import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="idrive-e2"
    name="IDrive E2"
    shortDescription="Store website files and database backups on IDrive E2."
    website={"https://www.idrive.com/e2/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="idrive-e2-ico.png"
    metaTitle={meta.title.replace('##provider##', 'IDrive E2')}
    metaDescription={meta.description.replace('##provider##', 'IDrive E2')}
  >
    <IntegrationStorageContent
      keyRef="idrive-e2"
      service="IDrive E2"
      provider="IDrive E2"
      learnTags={['idrive-e2']}
    />
  </IntegrationTemplate>
)

export default ContentPage
